<template>
  <div class="advanced-select" v-on-click-outsite="deactivate" :class="{ 'open-up': openUp }">
    <div class="input-group">
      <input
        class="form-control"
        dir="ltr"
        :type="inputType"
        :aria-label="label"
        :placeholder="placeholder"
        v-model="query"
        @focus="activate"
        @input="handleInput($event)"
        @paste="handleInput"
        @keyup.esc="deactivate"
	@click="activate"
	ref="theInput"
      />
    </div>
    <div class="form-select" dir="ltr" :aria-label="label" v-if="isActive && useFormSelect">
      <div
        class="form-select-option"
        v-for="option in filteredOptions"
        :key="option.k || option"
        :value="option.k || option"
        @click="handleSelect(option.k||option,$event)"
        v-html="option.hl||option"
      >
      </div>
    </div>
  </div>
</template>

<script>
import {onMounted, ref, watch} from "vue";
import clickOutside from "@/directives/click-outside";

export default {
  name: "AdvancedSelect",
  props: {
    inputType: {
      type: String,
      default: "text",
    },
    openUp: {
      type: Boolean,
      default: false,
    },
    dummy: {
      type: Boolean,
    },
    label: { type: String },
    options: { type: Array },
    placeholder: { type: String },

    useFormSelect: {
      type: Boolean,
      default: true,
    },
  },
  directives: {
    onClickOutsite: clickOutside(),
  },
  setup(props, ctx) {
    const filteredOptions = ref([]);
    const query = ref(localStorage.getItem(`advancedSelectQuery_${props.label}`) || "");
    const isActive = ref(false);
    const theInput = ref(null);

    onMounted(() => {
        filterOptions();
    })

    watch(() =>  props.dummy, (val) => {
      if(val)
      { query.value = ''}
    })

    function clear() {
      query.value = ""
    }
    function activate() {
      isActive.value = true;
    }

    function deactivate() {
      isActive.value = false;
    }

    function filterOptions({ value: query } = { value: "" }) {
      const q = query && query.trim().toLocaleLowerCase();
      if(!q) {
        filteredOptions.value = props.options;
      }else{
	const qq = q.replace(/[.*+?^${}()|[\]\\]/g,"\\$&");
        const re = RegExp("(^|\\s|[^\\u0590-\\u05FF])(" + qq + ")",'gi');
        filteredOptions.value = props.options.map(x=>{
          const hl = x.replaceAll(re,'$1<b>$2</b>');
          if(hl==x) return null;
          return {k:x,hl};
        }).filter(x=>x);
      }
    }


    function handleSelect(option) {
        ctx.emit("select", query.value = option);
        handleInput()
        theInput.value.focus();
	const il = theInput.value.length;
        theInput.value.setSelectionRange(il,il);

        deactivate()
	ctx.emit("selected");
    }

    function handleInput($event) {
      ctx.emit("select", query.value);
      localStorage.setItem(`advancedSelectQuery_${props.label}`, query.value);
      return $event && filterOptions($event.target);
    }

    function focus() {
      theInput.value.focus();
    }

    return {
      activate,
      deactivate,
      filteredOptions,
      filterOptions,
      handleSelect,
      handleInput,
      isActive,
      query,
      clear,
      theInput,
      focus
    };
  },
};
</script>

<style scoped>
::placeholder {
  color: rgba(185, 84, 42, 0.68);
}

.open-up .form-select {
  bottom: 100%;
}

.advanced-select {
  margin: 8px;
  position: relative;
}

.advanced-select .form-select {
  background-image: none;
  max-height: 150px;
  overflow: auto;
  position: absolute;
  z-index: 100;
}

.advanced-select .form-select-option {
  cursor: pointer;
}

.advanced-select .input-group input {
  cursor: pointer;
}
</style>
