export default function clickOutside() {
    return {
        beforeMount: (el, binding) => {
            el.clickOutsideEvent = event => {
                if (!(el == event.target || el.contains(event.target))) {
                    // and if it did, call method provided in attribute value
                    binding.value();
                }
            };
            document.addEventListener("click", el.clickOutsideEvent);
        },
        unmounted: el => {
            document.removeEventListener("click", el.clickOutsideEvent);
        },
    }
}
