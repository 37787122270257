<template>
    <template v-if="file.file_type == 'image'">
        <div>
            <image-component  :file="file"></image-component>
        </div>
    </template>
    <template v-else-if="file.file_type == 'audio'">
        <div>
            <audio-component  :file="file"></audio-component>
        </div>
    </template>
    <template v-else>
        <link-component :file="file"></link-component>
    </template>
</template>
<script>
import AudioComponent from "./AudioComponent.vue";
import ImageComponent from "./ImageComponent.vue";
import LinkComponent from "./LinkComponent.vue";

export default {
    components: {
        AudioComponent,
        ImageComponent,
        LinkComponent
    },
    props: {
        file: Object,
        href: String
    }
};
</script>