import axios from "axios";


export async function fetchWithLastModified(url, localStorageKey, localStorageLastModifiedKey) {
    const hasCachedOptions = !!localStorage.getItem(localStorageKey);
    try {
        const requestHeaders =
            hasCachedOptions && localStorageLastModifiedKey
                ? {
                        "If-Modified-Since":
                            localStorage.getItem(localStorageLastModifiedKey),
                    }
                : {};
        const { data, headers } = await axios.get(url, {
            headers: requestHeaders,
        });
        
        localStorage.setItem(localStorageKey, JSON.stringify(data));
        localStorage.setItem(localStorageLastModifiedKey, headers.get("Last-Modified"));

        return data;
    } catch (e) {
        if (e.response.status == 304) {
            return JSON.parse(localStorage.getItem(localStorageKey));
        }

        return 
    }
}