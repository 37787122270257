<template>
    <a target="_blank" :href="file.url">
        <img
            class="rounded image-file"
            :src="file.url"
            :alt="file.name || file.name"
        />
    </a>
</template>
<script>
export default {
    props: {
        file: Object,
    },
};
</script>
