<template>
  <content-modal v-if="isVisible" @close="hideModal" class="advanced-search-modal">
    <template #header>
      <div>
        <h3>אוואנסירטער נאכזוך / חיפוש מתקדם / Advanced Search</h3>
      </div>
    </template>
    <template #body>
      <AdvancedSearchForm
          :dummy="dummy"
          :authors-url="authorsUrl"
          :forms-url="formsUrl"
          :genres-url="genresUrl"
          :meter-options-url="meterOptionsUrl"
          :regions-url="regionsUrl"
          :scales-url="scalesUrl"
          :sources-url="sourcesUrl"
          :tempo-options-url="tempoOptionsUrl"
          :time-options-url="timeOptionsUrl"
          :titles-url="titlesUrl"
          :traditions-url="traditionsUrl"
          :dynasties-url="dynastiesUrl"
          :keywords-url="keywordsUrl"
          :translations="translations"
          @updated="getValues"
          @formSubmitted="goToSearchPage"
      ></AdvancedSearchForm>
    </template>
    <template #footer>
      <button class="btn clear" type="submit" @click.prevent="goToSearchPage">
        {{ translations.search }}
      </button>
      <button class="btn search" type="reset" @click.prevent="clearAllInputs">
        {{ translations.clear }}
      </button>
    </template>
  </content-modal>
</template>

<script>
import {getCurrentInstance, nextTick, ref, watch} from "vue";

import ContentModal from "../../shared/components/ContentModal.vue";
import AdvancedSearchForm from "./AdvancedSearchForm.vue";


export default {
  name: "AdvancedSearchModal",
  props: {
    searchUrl: {
      type: String,
      required: true,
    },
    authorsUrl: {
      type: String,
      required: true,
    },
    formsUrl: {
      type: String,
      required: true,
    },
    genresUrl: {
      type: String,
      required: true,
    },
    meterOptionsUrl: {
      type: String,
      required: true,
    },
    regionsUrl: {
      type: String,
      required: true,
    },
    scalesUrl: {
      type: String,
      required: true,
    },
    sourcesUrl: {
      type: String,
      required: true,
    },
    tempoOptionsUrl: {
      type: String,
      required: true,
    },
    timeOptionsUrl: {
      type: String,
      required: true,
    },
    titlesUrl: {
      type: String,
      required: true,
    },
    traditionsUrl: {
      type: String,
      required: true,
    },
    dynastiesUrl: {
      type: String,
      required: true,
    },
    keywordsUrl: {
      type: String,
      required: true,
    },
    translations: {
      type: Object,
      required: true
    }
  },
  components: {
    AdvancedSearchForm,
    ContentModal,
  },

  setup(props) {
    const isVisible = ref(false);
    const eventBus =
        getCurrentInstance().appContext.config.globalProperties.$eventBus;

    eventBus.on("advanced-search:show", showModal);

   const dummy = ref(false)

    watch(() => dummy.value, () => {
      if(dummy.value) {
        nextTick(() => {
          dummy.value = false
        })
      }
    })


function clearAllInputs() {
      dummy.value = true

      const inputElements = document.querySelectorAll("input");

      inputElements.forEach((input) => {
        if (
            input.type !== "button" &&
            input.type !== "submit" &&
            input.type !== "reset"
        ) {
          input.value = "";
        }
      });
      clearLocalStorageByPrefix("advancedSelectQuery_");
    }

    function clearLocalStorageByPrefix(prefix) {
      for (let key in localStorage) {
        if (key.startsWith(prefix)) {
          localStorage.removeItem(key);
        }
      }
      localStorage.removeItem("params");
    }

    function showModal() {
      isVisible.value = true;
    }

    function hideModal() {
      isVisible.value = false;
    }

    function goToSearchPage() {
      const qs = Object.entries(getValues())
        .filter(kv=>kv[1])
        .map( kv=>kv.map(x=>encodeURIComponent(x)).join('=') )
        .join('&');
      window.location.href = `${props.searchUrl}?${qs}`;
    }

    function getValues() {
      const lspfx='advancedSelectQuery_'
      function gi() {
        for(const lsi of Array.from(arguments)) {
          const rv = localStorage.getItem(lspfx+lsi);
          if(rv) return rv.replace(/^\uFEFF/,'');
        }
        return undefined
      }
      return {
        asname: gi("name", "שם"),
        asform: gi("form"),
        asgenre: gi("genre"),
        asmeter: gi("meter"),
        asregion: gi("region","חבל ארץ / געגנט"),
        asscale: gi("scale"),
        assource: gi("source","מקור"),
        astime: gi("time","זמן"),
        astitleoptions: gi("title options","קעפל / כותר"),
        astempo: gi("tempo"),
        asplace: gi("place / Ort","מקום מושב / ארט"),
        asdynasty: gi("dynasty / Khsides","חסידות"),
        askeywords: gi("keywords / Verter","מילים / ווערטער")
      };

    }

    return {
      hideModal,
      showModal,
      isVisible,
      getValues,
      goToSearchPage,
      clearAllInputs,
      dummy
    };
  },
};
</script>
<style scope>
.advanced-search-modal .modal-container {
  height: max-content;
}
</style>
