<template>
    <button
        class="btn dynasties-toggle"
        type="button"
        @click="toggleState"
        v-on-click-outsite="() => toggleState({ state: false })"
        @keyup.esc="toggleState({ state: false })"
    >
        {{ label }}
    </button>
    <div>
        <ul
            class="form-select"
            dir="ltr"
            :aria-label="label"
            v-if="isActive"
        >
            <template v-if="isLoading">
                <li>Loading...</li>
            </template>
            <template v-for="dynasty in dynasties" :key="dynasty">
                <li>
                    <search-link :dynasty="dynasty" target="_self">{{ dynasty }}</search-link>
                </li>
            </template>
        </ul>
    </div>
</template>

<script>
import { ref, onMounted } from "vue";

import clickOutside from "@/directives/click-outside";

import { fetchWithLastModified } from "../../utils/api.js"
import SearchLink from "../../shared/components/SearchLink.vue"

export default {
    name: "DynastyButton",
    components: { SearchLink },
    props: {
        label: {
            type: String,
            required: true,
        },
        getUrl: {
            type: String,
            required: true
        }
    },
    directives: {
        onClickOutsite: clickOutside(),
    },
    setup(props) {
        const dynasties = ref([])
        const isActive = ref(false);
        const isLoading = ref( true )

        onMounted(
            async () => {
                isLoading.value = true
                const response = await fetchWithLastModified( props.getUrl, "dynasties-list", "dynasties-list:last-modified" )

                if ( response ) {
                    dynasties.value = response
                    isLoading.value = false
                }
            }
        )

        function toggleState({ state = !isActive.value }) {
            isActive.value = state;
        }

        return {
            isActive,
            isLoading,
            toggleState,
            dynasties
        };
    },
};
</script>

<style scoped>
.dynasties-list .dynasties-toggle {
    width: 100%;
    margin: 0;
}

.dynasties-list .form-select {
    background-image: none;
    max-height: 255px;
    width: 200px;
    right: 0;
    overflow: auto;
    position: absolute;
    z-index: 100;
    list-style: none;
}

@media (min-width: 768px) {
    .dynasties-list .form-select {
        width: 400px;
    }
}

.form-select a {
    color: #6c757d;
    text-decoration: none;
}

.form-select a:hover {
    text-decoration: underline;
}
</style>
