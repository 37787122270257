<template>
  <form class="advanced-search-form" v-if="isLoaded" @submit.prevent="handleSubmit" ref="theForm">
    <div class="first-block">
      <AdvancedSelect
          :dummy="dummy"
          :label="translations.keyword"
          :placeholder="translations.keyword"
          @select="updateValue('keyword', $event)"
          :useFormSelect="false"
          :input-type="'search'"
          ref="theKeywords"
      ></AdvancedSelect>

      <AdvancedSelect
                    :dummy="dummy"

          :label="translations.scales"
          :placeholder="translations.scales"
          :options="formOptions.scales"
          @select="updateValue('scale', $event)"
          @selected="selected()"
          :input-type="'search'"

      ></AdvancedSelect>

      <AdvancedSelect
                    :dummy="dummy"

          :label="translations.sources"
          :placeholder="translations.sources"
          :options="formOptions.sources"
          @select="updateValue('source', $event)"
          @selected="selected()"
          :input-type="'search'"

      ></AdvancedSelect>
      <AdvancedSelect
                    :dummy="dummy"

          :label="translations.form"
          :placeholder="translations.form"
          :options="formOptions.form"
          :input-type="'search'"
          @select="updateValue('form', $event)"
          @selected="selected()"
      ></AdvancedSelect>
      <AdvancedSelect
                    :dummy="dummy"

          :label="translations.names"
          :placeholder="translations.names"
          :options="formOptions.authors"
          :input-type="'search'"
          @select="updateValue('author', $event)"
          @selected="selected()"
      ></AdvancedSelect>
      <AdvancedSelect
                    :dummy="dummy"

          :label="translations.tempo"
          :placeholder="translations.tempo"
          :options="formOptions.tempo"
          :input-type="'search'"
          @select="updateValue('tempo', $event)"
          @selected="selected()"
      ></AdvancedSelect>
      <AdvancedSelect
                    :dummy="dummy"

          :label="translations.titles"
          :placeholder="translations.titles"
          :options="formOptions.titles"
          :input-type="'search'"
          @select="updateValue('title', $event)"
          @selected="selected()"
      ></AdvancedSelect>
      <AdvancedSelect
                    :dummy="dummy"

          :label="translations.genre"
          :placeholder="translations.genre"
          :options="formOptions.genre"
          :input-type="'search'"
          @select="updateValue('genre', $event)"
          @selected="selected()"
      ></AdvancedSelect>
      <AdvancedSelect
                    :dummy="dummy"

          :label="translations.times"
          :placeholder="translations.times"
          :options="formOptions.times"
          :input-type="'search'"
          @select="updateValue('time', $event)"
          @selected="selected()"
      ></AdvancedSelect>

      <AdvancedSelect
                    :dummy="dummy"

          :label="translations.meter"
          :placeholder="translations.meter"
          :options="formOptions.meter"
          :input-type="'search'"
          @select="updateValue('meter', $event)"
          @selected="selected()"
      ></AdvancedSelect>

    </div>
    <div class="second-block">
      <AdvancedSelect
                    :dummy="dummy"

          :label="translations.dynasty"
          :placeholder="translations.dynasty"
          :options="formOptions.dynasties"
          :open-up="true"
          :input-type="'search'"
          @select="updateValue('dynasty', $event)"
          @selected="selected()"
      ></AdvancedSelect>
      <AdvancedSelect
                    :dummy="dummy"

          :label="translations.regions"
          :placeholder="translations.regions"
          :options="formOptions.regions"
          :open-up="true"
          :input-type="'search'"
          @select="updateValue('region', $event)"
          @selected="selected()"
      ></AdvancedSelect>
      <AdvancedSelect
                    :dummy="dummy"

          :label="translations.traditions"
          :placeholder="translations.traditions"
          :options="formOptions.traditions"
          :open-up="true"
          :input-type="'search'"
          @select="updateValue('tradition', $event)"
          @selected="selected()"
      ></AdvancedSelect>
    </div>

  <input type="submit" style="display: none"/>
  </form>
  <div v-else>Loading...</div>
</template>

<script>
import {onMounted, reactive, ref, nextTick, watch} from "vue";

import axios from "axios";

import AdvancedSelect from "../../shared/components/AdvancedSelect.vue";

export default {
  name: "AdvancedSearchButton",
  props: {
    dummy: {
      type: Boolean
    },
    authorsUrl: {
      type: String,
      required: true,
    },
    formsUrl: {
      type: String,
      required: true,
    },
    genresUrl: {
      type: String,
      required: true,
    },
    meterOptionsUrl: {
      type: String,
      required: true,
    },
    regionsUrl: {
      type: String,
      required: true,
    },
    scalesUrl: {
      type: String,
      required: true,
    },
    sourcesUrl: {
      type: String,
      required: true,
    },
    tempoOptionsUrl: {
      type: String,
      required: true,
    },
    timeOptionsUrl: {
      type: String,
      required: true,
    },
    titlesUrl: {
      type: String,
      required: true,
    },
    traditionsUrl: {
      type: String,
      required: true,
    },
    translations: {
      type: Object,
      required: true,
    },
    dynastiesUrl: {
      type: String,
      required: true,
    },
    keywordsUrl: {
      type: String,
      required: true,
    },
  },
  components: {
    AdvancedSelect,
  },

  setup(props, ctx) {
    const theForm = ref(null);
    const isLoaded = ref(false);
    const form = reactive({
      author: localStorage.getItem("advancedSelectQuery_name") || "",
      form: localStorage.getItem("advancedSelectQuery_form") || "",
      genre: localStorage.getItem("advancedSelectQuery_genre") || "",
      meter: localStorage.getItem("advancedSelectQuery_meter") || "",
      region: localStorage.getItem("advancedSelectQuery_region") ||  "",
      scale: localStorage.getItem("advancedSelectQuery_scale") || "",
      source: localStorage.getItem("advancedSelectQuery_source") || "",
      time: localStorage.getItem("advancedSelectQuery_time") || "",
      title: localStorage.getItem("advancedSelectQuery_title options") || "",
      tempo: localStorage.getItem("advancedSelectQuery_tempo") || "",
      tradition: localStorage.getItem("advancedSelectQuery_place / Ort") || "",
      dynasty: localStorage.getItem("advancedSelectQuery_dynasty / Khsides") || "",
      keyword: localStorage.getItem("advancedSelectQuery_keywords") || "",
    });
    const formOptions = reactive({
      authors: [],
      form: [],
      genre: [],
      meter: [],
      regions: [],
      scales: [],
      sources: [],
      tempo: [],
      times: [],
      titles: [],
      traditions: [],
      dynasties: [],
      keywords: [],
    });

    function getFormValueForURL() {
      return Object.values(form).join(" ");
    }

    function updateValue(key, value) {
      form[key] = value;
      ctx.emit("updated", getFormValueForURL());
    }

    const theKeywords = ref(null);
    function selected() {
      // theKeywords.value.focus();
    }

    onMounted(async () => {
      isLoaded.value = false;
      try {
        const [
          authors,
          form,
          genre,
          meter,
          regions,
          scales,
          sources,
          tempo,
          times,
          titles,
          traditions,
          dynasties,
          keywords,
        ] = await Promise.all([
          getRequest(props.authorsUrl, "advanced-search:authors"),
          getRequest(props.formsUrl, "advanced-search:forms"),
          getRequest(props.genresUrl, "advanced-search:genres"),
          getRequest(props.meterOptionsUrl, "advanced-search:meter"),
          getRequest(props.regionsUrl, "advanced-search:regions"),
          getRequest(props.scalesUrl, "advanced-search:scales"),
          getRequest(props.sourcesUrl, "advanced-search:sources"),
          getRequest(props.tempoOptionsUrl, "advanced-search:tempo"),
          getRequest(props.timeOptionsUrl, "advanced-search:time"),
          getRequest(props.titlesUrl, "advanced-search:titles"),
          getRequest(
              props.traditionsUrl,
              "advanced-search:traditions"
          ),
          getRequest(props.dynastiesUrl, "advanced-search:dynasties"),
          getRequest(props.keywordsUrl, "advanced-search:keywords"),
        ]);

        Object.assign(formOptions, {
          authors,
          form,
          genre,
          meter,
          regions,
          scales,
          sources,
          tempo,
          times,
          titles,
          traditions,
          dynasties,
          keywords,
        });

        isLoaded.value = true;
      } catch (e) {
        // pass
      }
    });

    async function getRequest(url, localStorageKeyPerfix) {
      const dataKey = `${localStorageKeyPerfix}:options`;
      const lastModifiedKey = `${localStorageKeyPerfix}:last-modified`;
      const hasCachedOptions = !!localStorage.getItem(dataKey);
      try {
        const requestHeaders =
            hasCachedOptions && lastModifiedKey
                ? {
                  "If-Modified-Since":
                      localStorage.getItem(lastModifiedKey),
                }
                : {};
        const {data, headers} = await axios.get(url, {
          headers: requestHeaders,
        });

        localStorage.setItem(dataKey, JSON.stringify(data));
        localStorage.setItem(lastModifiedKey, headers.get("Last-Modified"));

        return data;
      } catch (e) {
        if (e.response.status === 304) {
          return JSON.parse(localStorage.getItem(dataKey));
        }

        return null;
      }
    }

    watch(isLoaded,async (v)=>{
      if(!v) return;
      await nextTick();
      const firstInput = theForm.value.querySelector('input');
      if (firstInput) {
        firstInput.focus();
      }
    });

    return {
      updateValue,
      selected,
      isLoaded,
      formOptions,
      handleSubmit: function handleSubmit() {
        ctx.emit('formSubmitted');
      },
      theForm,
      theKeywords
    };
  },
};
</script>

<style scoped>
.advanced-search-form ::placeholder {
  text-transform: capitalize;
}

.first-block {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.second-block {
  width: 50%;
  margin: auto;
}

</style>
