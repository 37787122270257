<template>
    <a target="_blank" :href="file.url">{{ file.name || file.name }}</a>
</template>
<script>
export default {
    props: {
        file: Object,
    },
};
</script>
