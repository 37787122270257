<template>
    <div>
        <div v-if="titles" class="nigun__title">
            <h4>
                {{ titles }}
            </h4>
        </div>
        <div class="nigun">
            <p class="nigun__number">
                <a
                    class="btn mx-2"
                    target="_blank"
                    :href="`${createRequestUrl}?nigun_id=${nigun.id}`"
                >
                    <i class="bi bi-file-earmark-plus"></i>
                    {{ addNigunTranslation }}
                </a>
                <span>{{ nignTranslation }} {{ nigun.id }}</span>
                <a v-if="editUrl" class="m-1" :href="editUrl">
                    <i class="bi bi-pencil"></i>
                </a>
            </p>

            <div class="nigun__media" v-if="nigun.image || nigun.mp3_file">
                <template v-if="nigun.image">
                    <img class="nigun-image mb-2" :src="nigun.image" />
                </template>
                <audio v-if="nigun.mp3_file" :key="nigun.mp3_file" controls ref="recording">
                    <source :src="nigun.mp3_file" type="audio/mpeg" />
                    Your browser does not support the audio tag.
                </audio>
            </div>

            <div class="nigun__details">
                <ul>
                    <li v-if="nigun.traditions.length">
                        <ul>
                            <template
                                v-for="tradition in nigun.traditions"
                                :key="tradition"
                            >
                                <li>
                                    <search-link :dynasty="tradition">{{ tradition }}</search-link>
                                </li>
                            </template>
                        </ul>
                    </li>
                    <li v-if="nigun.contexts.length">
                        <ul>
                            <template
                                v-for="context in nigun.contexts"
                                :key="context"
                            >
                                <li>{{ context }}</li>
                            </template>
                        </ul>
                    </li>

                    <li v-if="nigun.authors.length">
                        <ul>
                            <template
                                v-for="author in nigun.authors"
                                :key="author"
                            >
                                <li>{{ author }}</li>
                            </template>
                        </ul>
                    </li>

                    <li v-if="nigun.collection">
                        <ul>
                            <li>
                                <search-link :source="nigun.collection"><span
                                    v-for="(line, lineNumber) of collectionPretty" 
                                    v-bind:key="lineNumber"
                                >
                                    {{ line }}<br/>
                                </span></search-link>
                            </li>
                        </ul>
                    </li>
                    <li v-if="nigun.description">
                        <ul>
                            <li v-html="nigun.description"></li>
                        </ul>
                    </li>

                    <li v-if="nigun.links.length">
                        <ul>
                            <template v-for="link in nigun.links" :key="link">
                                <li>
                                    <a :href="link">{{ link }}</a>
                                </li>
                            </template>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
        <template v-if="nigun.related_files.length">
            <div class="row mt-4 mb-4 nigun__related-files">
                <div class="d-flex flex-wrap gap-3">
                    <template
                        v-for="file of nigun.related_files"
                        :key="file.url"
                    >
                        <media-file-component
                            :file="file"
                        ></media-file-component>
                    </template>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
import { computed, ref, unref, watch } from "vue";

import { parseObject } from "../utils/objects.js";

import MediaFileComponent from "../shared/components/MediaFileComponent.vue";
import SearchLink from "../shared/components/SearchLink.vue"

export default {
    name: "NigunDetail",
    components: { MediaFileComponent, SearchLink },
    props: {
        editUrl: String,
        nigun: Object,
    },
    setup(props) {
        const TRANSLATIONS_DATA = parseObject("TRANSLATIONS_DATA");
        const SEARCH_RESULTS_DATA = parseObject("SEARCH_RESULTS_DATA");

        const titles = computed(() => props.nigun.titles.slice(1).join(" "));
        const recording = ref();
        const collectionPretty = computed(() => props.nigun.collection.split(RegExp(/–|-/)))
        const createRequestUrl = SEARCH_RESULTS_DATA.CREATE_REQUEST_URL

        watch(
            () => props.nigun,
            () => {
                unref(recording) && unref(recording).pause();
            }
        );
        return {
            collectionPretty,
            createRequestUrl,
            recording,
            titles,
            nignTranslation: TRANSLATIONS_DATA.NIGN_TRANS,
            addNigunTranslation: TRANSLATIONS_DATA.ADD_NIGUN_TRANS
        };
    },
};
</script>

<style>

.nigun__related-files img {
    width: 300px;
    cursor: zoom-in;
}
</style>
<style scoped>
.nigun {
    display: grid;
    grid-gap: 10px;
}

.nigun__title {
    text-align: center;
    color: #000000;
    margin: 20px 0;
    text-transform: capitalize;
}

.nigun__number {
    text-transform: capitalize;
    align-items: end;
    justify-content: flex-start;
    justify-items: flex-start;
    text-align: end;
}

.nigun-image {
    width: 100%;
}

.nigun__details {
    display: flex;
    flex-wrap: wrap;
}

.nigun__details ul {
    flex: 1;
}

.nigun__details > ul {
    list-style: none;
    padding: 0;
}

.nigun__related-files {
    width: 100%;
}

@media (min-width: 992px) {
    .nigun {
        grid-template-columns: 400px auto;
    }

    .nigun__number {
        text-transform: capitalize;
        grid-column: 1/3;
    }

    .nigun__media {
        grid-row: 2;
        grid-column: 1/2;
    }

    .nigun__details {
        grid-column: 2/3;
        grid-row: 2;
    }
}
</style>
