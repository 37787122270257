<template>
    <audio controls>
        <source :src="file.url" type="audio/mpeg">
        Your browser does not support the audio tag.
    </audio>
</template>
<script>
export default {
    props: {
        file: Object
    }
}
</script>