<template>
  <a :href="searchUrl" target="_blank"><slot></slot></a>
</template>

<script>
const propsies = {
  query: 'query',
  source: 'assource',
  dynasty: 'asdynasty'
}

export default {
  props: {
    ...Object.fromEntries(
      Object.keys(propsies).map(k=>[
        k,
        {
          type: String,
          required: false
        }
      ])
    ),
    target: String
  },
  computed: {
    searchUrl() {
      const qs = Object.entries(propsies)
        .map(([k,v])=>this[k] && `${v}=${encodeURIComponent(this[k])}`)
        .filter(x=>x)
        .join('&')
      return `/search/?${qs}`;
    },
    aTarget() {
      return this.target || '_blank';
    }
  }
}
</script>
