<template>
    <button
        id="advanced_search_button"
        class="btn"
        @click="showAdvancedSearch"
    >
        {{ label }}
    </button>
</template>

<script>
import { getCurrentInstance } from "vue";

export default {
    name: "AdvancedSearchButton",
    props: {
        label: {
            type: String,
            required: true
        }
    },
    setup() {
        const eventBus = getCurrentInstance().appContext.config.globalProperties.$eventBus;

        function showAdvancedSearch() {
            eventBus.emit("advanced-search:show");
        }

        return {
            showAdvancedSearch,
        };
    },
};
</script>
